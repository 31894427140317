import React from "react";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import SectionTitle from "../../../general/SectionTitle/SectionTitle";
import { UnderlineSlider } from "../../slider/UnderlineSlider/UnderlineSlider";
import styles from "./RelatedArticles.module.scss";
import DetailLine from "../../../general/borders/DetailLine/DetailLine";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
import Volt from "../../../general/Volt/Volt";

interface RelatedArticlesProps {
  titleParts: Array<string>;
  articles: Array<ContentstackArticle>;
}

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({ titleParts, articles }) => {
  const oneLineTitle = titleParts.join(" ");

  return (
    <section className={styles.relatedArticles}>
      <Volt className={styles.topVolt} name={"VoltTop01"} position="top" />
      <div className={`container`}>
        <div className={`content-wrapper ${styles.header}`}>
          <DetailLine className={styles.copyWrapper} variation="halfTop" theme={ColorTheme.LIGHT}>
            <SectionTitle className={styles.title} titleParts={[oneLineTitle]} theme={ColorTheme.LIGHT} />
          </DetailLine>
        </div>
      </div>
      <UnderlineSlider slides={articles} />
    </section>
  );
};
