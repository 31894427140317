import React, { useMemo, useCallback, useState, useEffect } from "react";
import Icon from "../Icon/Icon";

type RedditShareProps = {
  text?: string;
  iconClassName?: string;
  anchorClassName?: string;
};

export const RedditShare: React.FC<RedditShareProps> = ({ text, iconClassName, anchorClassName }) => {
  const [documentTitle, setDocumentTitle] = useState<string>("");

  useEffect(() => {
    // Give time for title to update
    setTimeout(() => setDocumentTitle(document.title), 100);
  }, []);

  const shareUrl = useMemo(() => {
    return encodeURI(
      `http://www.reddit.com/submit/?url=${
        typeof window !== "undefined" ? window.location.href.replace("#", "") : ""
      }&title=${text ? text : documentTitle}`,
    );
  }, [text, documentTitle]);

  const handleClick = useCallback(() => {
    typeof window !== "undefined" && window.open(shareUrl, "popup", "width=840,height=600");
    return false;
  }, [shareUrl]);

  return (
    <a className={anchorClassName ? anchorClassName : ""} target="popup" onClick={handleClick}>
      <Icon className={iconClassName ? iconClassName : ""} name="Reddit" />
    </a>
  );
};
