import React from "react";
import { ContentstackArticleSeparatorBlock } from "../../../../data/types/Contentstack";
import styles from "./SeparatorBlock.module.scss";

export const SeparatorBlock: React.FC<ContentstackArticleSeparatorBlock> = ({ type, customAsset }) => (
  <>
    {type === "Custom" ? (
      customAsset && <img className={styles.customSeparator} src={customAsset?.url} />
    ) : (
      <div className={`${styles.separator} ${styles[type]}`}>
        <span className={styles.lineWrapper}>
          <span className={styles.line} />
        </span>
      </div>
    )}
  </>
);
