enum AbilityName {
  BaseStats = "base_stats",
  Passive = "passive",
  Ultimate = "ultimate",
}

export const getAbilityIndex = (abilityName: string) => {
  switch (abilityName) {
    case AbilityName.BaseStats:
      return -1;
    case AbilityName.Passive:
      return 0;
    case AbilityName.Ultimate:
      return 4;
    default:
      return parseInt(abilityName);
  }
};
