import React from "react";
import { ContentstackArticleAuthorsBlock, ContentstackAsset } from "../../../../data/types/Contentstack";
import { AuthorCard } from "../../../content/cards/AuthorCard/AuthorCard";
import styles from "./AuthorsBlock.module.scss";

type AuthorsBlockProps = {
  data: ContentstackArticleAuthorsBlock;
  authorFallbackImage: ContentstackAsset;
};

export const AuthorsBlock: React.FC<AuthorsBlockProps> = ({ data: { authors }, authorFallbackImage }) => (
  <ul className={styles.authorBlockList}>
    {authors.map((author) => (
      <li key={author.name} className={styles.authorBlockItem}>
        <AuthorCard {...author} fallbackImage={authorFallbackImage} />
      </li>
    ))}
  </ul>
);
