import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Locale from "../../../../data/contexts/riot-locale";
import { loadTwitter } from "./twitterAPI";
import Loader from "../../../general/Loader/Loader";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
import styles from "./Tweet.module.scss";

export const Tweet: FC<{ id: string }> = ({ id }) => {
  const tweetRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const locale = useContext(Locale);

  useEffect(() => {
    tweetRef.current &&
      loadTwitter().then(() =>
        window.twttr.widgets
          .createTweet(id, tweetRef.current as HTMLElement, {
            align: "center",
            lang: locale.slice(0, 2),
          })
          .then(() => setLoaded(true)),
      );
  }, [id, locale]);

  return (
    <>
      {!loaded && (
        <div className={styles.loaderWrapper}>
          <Loader relativeSize={true} theme={ColorTheme.DARK} />
        </div>
      )}
      <div ref={tweetRef}></div>
    </>
  );
};
