import React, { useMemo, useCallback, useState, useEffect } from "react";
import Icon from "../Icon/Icon";

type TwitterShareProps = {
  text?: string;
  hashtags?: Array<string>;
  iconClassName?: string;
  anchorClassName?: string;
};

export const TwitterShare: React.FC<TwitterShareProps> = ({ hashtags, text, iconClassName, anchorClassName }) => {
  const [documentTitle, setDocumentTitle] = useState<string>("");

  useEffect(() => {
    // Give time for title to update
    setTimeout(() => setDocumentTitle(document.title), 100);
  }, []);

  const shareUrl = useMemo(() => {
    const hashtagsString = !!hashtags ? hashtags.join(",") : "";
    const shareText = text ? text : documentTitle;

    return encodeURI(
      `https://twitter.com/intent/tweet/?url=${
        typeof window !== "undefined" ? window.location.href : ""
      }&text=${shareText}&hashtags=${hashtagsString}`,
    );
  }, [hashtags, text, documentTitle]);

  const handleClick = useCallback(() => {
    typeof window !== "undefined" && window.open(shareUrl, "popup", "width=480,height=240");
    return false;
  }, [shareUrl]);

  return (
    <a className={anchorClassName ? anchorClassName : ""} target="popup" onClick={handleClick}>
      <Icon className={iconClassName ? iconClassName : ""} name="Twitter" />
    </a>
  );
};
