import React, { FC } from "react";
import { ContentstackArticleVideoBlock } from "../../../../data/types/Contentstack";
import { Video } from "../../../general/Video/Video";
import styles from "./VideoBlock.module.scss";
import { TwoSideBorder } from "../../../general/borders/TwoSideBorder/TwoSideBorder";
import { ColorTheme } from "../../../../data/enum/ColorTheme";

export const VideoBlock: FC<ContentstackArticleVideoBlock> = ({ autoplay, showControls, items }) => {
  return (
    <>
      {items.map((item) => (
        <div className={styles.videoBlock} key={`video-${item}`}>
          <div className={styles.videoWrapper}>
            <Video
              className={styles.video}
              source={{ mp4Url: item.video.file.url }}
              autoplay={autoplay}
              showControls={showControls}
            />
            <TwoSideBorder className={styles.videoBorder} theme={ColorTheme.ELECTRIC} position="bottomRight" />
          </div>
        </div>
      ))}
    </>
  );
};
