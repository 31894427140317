import React, { useCallback, useMemo } from "react";
import {
  ContentstackOption,
  ContentstackArticleAuthorsBlock,
  ContentstackAsset,
} from "../../../../data/types/Contentstack";
import { AuthorsBlock } from "../../../block/news-article/AuthorsBlock/AuthorsBlock";
import Icon from "../../../general/Icon/Icon";
import styles from "./ArticleFooter.module.scss";
import { TwitterShare } from "../../../general/social/TwitterShare";
import { FacebookShare } from "../../../general/social/FacebookShare";
import { RedditShare } from "../../../general/social/RedditShare";

interface ArticleFooterProps {
  tags: Array<ContentstackOption>;
  authorsBlock?: ContentstackArticleAuthorsBlock;
  tagsCopy: string;
  shareCopy: string;
  authorFallbackImage: ContentstackAsset;
}

export const ArticleFooter: React.FC<ArticleFooterProps> = ({
  tags,
  authorsBlock,
  tagsCopy,
  shareCopy,
  authorFallbackImage,
}) => {
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(location.href);
  }, []);

  const twitterHashtags = useMemo(() => tags.map((tag) => tag.title.replace(/ /g, "")), [tags]);

  return (
    <div className={styles.articleFooter}>
      {authorsBlock && <AuthorsBlock data={authorsBlock} authorFallbackImage={authorFallbackImage} />}
      <div className={styles.social}>
        <p className={`label-03 ${styles.label}`}>{shareCopy}</p>
        <ul className={styles.socialList}>
          <li className={styles.socialIconItem}>
            <FacebookShare anchorClassName={styles.socialIconWrapper} iconClassName={styles.socialIcon} />
          </li>
          <li className={styles.socialIconItem}>
            <TwitterShare
              hashtags={["WildRift", ...twitterHashtags]}
              anchorClassName={styles.socialIconWrapper}
              iconClassName={styles.socialIcon}
            />
          </li>
          <li className={styles.socialIconItem}>
            <RedditShare anchorClassName={styles.socialIconWrapper} iconClassName={styles.socialIcon} />
          </li>
          <li className={styles.socialIconItem}>
            <a className={styles.socialIconWrapper} href="#" onClick={handleCopyToClipboard}>
              <Icon className={styles.socialIcon} name="Link" />
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.separator}>
        <span className={styles.dashDetail} />
      </div>
      <div className={styles.tags}>
        <p className={`label-03 ${styles.label}`}>{tagsCopy}</p>
        <ul className={styles.tagsList}>
          {tags.map((tag, index) => (
            <li className={styles.tagItem} key={index}>
              <p className={`copy-01 ${styles.tagCopy}`}>{tag.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
