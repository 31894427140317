import React from "react";
import styles from "./RichText.module.scss";

interface RichTextProps {
  content: string;
  className?: string;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const RichText: React.FC<RichTextProps> = ({ className = "", innerRef, content }) => (
  <div
    ref={innerRef}
    className={`${styles.richTextContent} ${className}`}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);
