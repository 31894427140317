import React from "react";
import { ContentstackArticleButtonsBlock } from "../../../../data/types/Contentstack";
import LinkButton from "../../../general/button/LinkButton/LinkButton";
import PrimaryButton from "../../../general/button/PrimaryButton/PrimaryButton";
import styles from "./ButtonsBlock.module.scss";
import { ColorTheme } from "../../../../data/enum/ColorTheme";

export const ButtonsBlock: React.FC<ContentstackArticleButtonsBlock> = ({ buttons }) => (
  <div className={`${styles.buttonsWrapper}`}>
    {buttons.map((button, index) => {
      return button.type === "Primary" ? (
        <div className={styles.button} data-size={buttons.length} key={index}>
          <PrimaryButton
            className={styles.buttonInline}
            theme={ColorTheme.DARK}
            label={button.cta.title}
            href={button.cta.href}
            hasWhiteBackground={true}
          />
        </div>
      ) : (
        <div className={styles.button} data-size={buttons.length} key={index}>
          <LinkButton key={index} label={button.cta.title} href={button.cta.href} />
        </div>
      );
    })}
  </div>
);
