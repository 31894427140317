import React, { FC } from "react";
import { ContentstackArticleYoutubeBlock } from "../../../../data/types/Contentstack";
import YoutubeVideo from "../../../general/YoutubeVideo/YoutubeVideo";
import styles from "./YoutubeBlock.module.scss";
import { TwoSideBorder } from "../../../general/borders/TwoSideBorder/TwoSideBorder";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
export const YouTubeBlock: FC<ContentstackArticleYoutubeBlock> = ({ videoId }) => {
  return (
    <div className={styles.youtubeBlock}>
      <YoutubeVideo className={styles.videoContainer} videoId={videoId} />
      <TwoSideBorder
        className={`${styles.videoBorder} ${styles.top}`}
        theme={ColorTheme.ELECTRIC}
        position="bottomRight"
      />
    </div>
  );
};
