import React, { FC } from "react";
import { ContentstackArticleTwitchBlock } from "../../../../data/types/Contentstack";
import { ensureEmbedType, TwitchVideoEmbed } from "./TwitchVideoEmbed";

export const TwitchBlock: FC<ContentstackArticleTwitchBlock> = ({ autoplay, embedId, embedType, muted, time }) => (
  <TwitchVideoEmbed
    embedId={embedId}
    embedType={ensureEmbedType(embedType)}
    autoPlay={autoplay}
    muted={muted}
    time={time}
  />
);
