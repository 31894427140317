import React, { useEffect, useState } from "react";
import styles from "./TwitchBlock.module.scss";

export type TwitchEmbedType = "channel" | "video" | "collection";
export interface Props {
  embedType: TwitchEmbedType;
  embedId: string;

  parent?: string;
  width?: string;
  height?: string;
  frameBorder?: number | string;
  scrolling?: "auto" | "yes" | "no";
  allowFullScreen?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  time?: string;
}

/**
 * Determines whether an arbitrary string is a valid TwitchEmbedType.
 * @param s a string
 * @returns whether s is a TwitchEmbedType
 */
export const isTwitchEmbedType = (s: string): s is TwitchEmbedType => ["channel", "video", "collection"].includes(s);

/**
 * Forces a string into TwitchEmbedType. Defaults to "channel" if s is not a valid
 * embed type.
 * @param s any string
 * @return s as a TwitchEmbedType if valid, "channel" otherwise.
 */
export const ensureEmbedType = (s: string): TwitchEmbedType => (isTwitchEmbedType(s) ? s : "channel");

export const TwitchVideoEmbed: React.FC<Props> = ({
  embedType,
  embedId,
  parent,
  width = "100%",
  height = "100%",
  frameBorder = 0,
  scrolling = "no",
  allowFullScreen = false,
  autoPlay = true,
  muted = false,
  time,
}: Props) => {
  const [hostName, setHostName] = useState<string>("");

  useEffect(() => {
    setHostName(parent || window?.location?.hostname);
  }, [parent]);

  // All parameters that will be sent to twitch.
  const srcParams = {
    [embedType]: embedId,
    parent: hostName,
    autoplay: autoPlay,
    muted,
    time,
  };
  const src = new URL("https://player.twitch.tv/");
  const searchString = Object.entries(srcParams)
    .filter(([, v]) => v || v === false)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
  src.search = searchString;

  return hostName ? (
    <div className={styles.twitchWrapper}>
      <iframe
        src={src.toString()}
        title="twitch embed" // TODO: ?
        height={height}
        width={width}
        frameBorder={frameBorder}
        scrolling={scrolling}
        allowFullScreen={allowFullScreen}
      ></iframe>
    </div>
  ) : (
    <></>
  );
};
