import React, { useContext, useMemo } from "react";
import { ContentstackOption, ContentstackArticleAuthorsBlock } from "../../../../data/types/Contentstack";
import styles from "./ArticleHeader.module.scss";
import { SeparatorBlock } from "../../../block/news-article/SeparatorBlock/SeparatorBlock";
import { localizeDateString } from "../../../../utils/date";
import Locale from "../../../../data/contexts/riot-locale";

interface ArticleHeaderProps {
  title: string;
  description: string;
  date: string;
  categories: Array<ContentstackOption>;
  authorsCopy: string;
  authorsBlock?: ContentstackArticleAuthorsBlock;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  title,
  description,
  date,
  categories,
  authorsBlock,
  authorsCopy,
}) => {
  const locale = useContext(Locale);
  const localizedDate = useMemo(() => localizeDateString(date, locale), [date, locale]);

  return (
    <>
      <span className={`copy-01`}>
        <b>{localizedDate}</b>
      </span>
      <span className={`heading-03 font-normal ${styles.category}`}>{categories[0].title}</span>
      <h1 className={`heading- font-normal ${styles.title}`}>{title}</h1>
      <p className={`copy-01 ${styles.description}`}>{description}</p>
      <div className={styles.authorsWrapper}>
        <span className={`heading- ${styles.authors}`}>{authorsCopy}</span>
        <ul className={styles.authorsList}>
          {authorsBlock?.authors.map((author) => (
            <li className={`copy-01 ${styles.authorName}`} key={author.name}>
              {author.name}
            </li>
          ))}
        </ul>
      </div>
      <SeparatorBlock type="Short" />
    </>
  );
};
