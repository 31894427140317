import React, { useCallback } from "react";
import { ContentstackArticleCarouselBlock } from "../../../../data/types/Contentstack";

import { useSlider } from "../../../../hooks/useSlider";
import SecondaryButton from "../../../general/button/SecondaryButton/SecondaryButton";
import { Video } from "../../../general/Video/Video";
import useGlobal from "../../../../store";
import styles from "./CarouselBlock.module.scss";
import Icon from "../../../general/Icon/Icon";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { isIE } from "../../../../utils/browser-detect";
import { RichText } from "../../../general/RichText/RichText";

type YoutubeSlideProps = {
  youtubeId: string;
};

const YoutubeSlide: React.FC<YoutubeSlideProps> = ({ youtubeId }) => {
  const [, { setYoutubeModalVideoId }] = useGlobal();
  const onPlayButtonClick = useCallback(() => {
    setYoutubeModalVideoId(youtubeId);
  }, [setYoutubeModalVideoId, youtubeId]);

  return (
    <div className={styles.posterContainer} data-element="poster">
      <Icon className={styles.playButton} onClick={onPlayButtonClick} name="PlayCircleThin" />
      <img
        src={`https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`}
        alt="youtube poster"
        className={styles.posterImage}
      />
      <span className={styles.overlay} />
    </div>
  );
};

/* * * */

export const CarouselBlock: React.FC<ContentstackArticleCarouselBlock> = ({ introduction, items }) => {
  const isMobile = useIsMobile();

  const {
    sliderRef,
    actions: { goTo, goToNext, goToPrevious },
    data: { activeSlideIndex, scrollSnaps },
  } = useSlider({ loop: true });

  return (
    <>
      <div className={styles.carouselBlock}>
        {introduction && <RichText className={styles.richText} content={introduction} />}
        <div className={styles.carouselWrapper}>
          <div className={styles.carousel} ref={sliderRef}>
            <ul className={styles.carouselContent}>
              {items?.map((item, index) => {
                if (item.image) {
                  return (
                    <li key={index} className={styles.slide}>
                      {isIE() ? (
                        <span className={styles.ieImage} style={{ backgroundImage: `url(${item.image?.url})` }} />
                      ) : (
                        <img className={styles.image} src={item.image?.url} />
                      )}
                    </li>
                  );
                } else if (item.video?.length) {
                  return (
                    <li key={index} className={styles.slide}>
                      <Video source={{ mp4Url: item.video[0].video.file.url }} autoplay />
                    </li>
                  );
                } else if (item.youtubeVideoId) {
                  return (
                    <li key={index} className={styles.slide}>
                      <YoutubeSlide youtubeId={item.youtubeVideoId} />
                    </li>
                  );
                }
              })}
            </ul>
            <div className={styles.arrowsWrapper}>
              <SecondaryButton
                className={`${styles.arrowPrev} ${styles.arrow}`}
                onClick={goToPrevious}
                icon="LongArrow"
                ariaLabel="previous"
              />
              <SecondaryButton
                className={`${styles.arrowNext} ${styles.arrow}`}
                onClick={goToNext}
                icon="LongArrow"
                ariaLabel="next"
              />
            </div>
          </div>
          <div className={`${styles.descriptionWrapper}`}>
            <ul className={styles.thumbnailsList}>
              {scrollSnaps.map((_, index) => (
                <li
                  key={index}
                  className={`${styles.thumbnail} ${activeSlideIndex === index ? styles.isActive : ""}`}
                  onClick={() => goTo(index)}
                >
                  {items && <img src={items?.[index].thumbnail.url} />}
                </li>
              ))}
            </ul>
            <h4 className={`heading-04 font-normal ${styles.slideTitle}`}>{items?.[activeSlideIndex].title}</h4>
            <div
              className={`copy-01 ${styles.copy}`}
              dangerouslySetInnerHTML={{ __html: items?.[activeSlideIndex].description || "" }}
            />
          </div>
          <div className={styles.frameBorderWrapper}>
            {isMobile ? (
              <Icon className={styles.frame} name="CarouselBlockMobileFrame" />
            ) : (
              <Icon className={styles.frame} name="CarouselBlockDesktopFrame" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
