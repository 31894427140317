import React, { FC } from "react";
import { ContentstackArticleTwitterBlock } from "../../../../data/types/Contentstack";
import { Tweet } from "./Tweet";
import styles from "./TwitterBlock.module.scss";

export const TwitterBlock: FC<ContentstackArticleTwitterBlock> = ({ tweets }) => (
  <div className={styles.blockWrapper}>
    {tweets.map((tweet, index) => (
      <Tweet id={tweet.tweetId} key={`tweet-${index}`} />
    ))}
  </div>
);
