import React, { useMemo } from "react";
import Icon from "../../Icon/Icon";
import styles from "./ArticleBackButton.module.scss";
import { ButtonColorTheme, ButtonColorThemeOption } from "../../../../data/enum/ColorTheme";
import { Link } from "gatsby-plugin-intl";

export interface ButtonProps {
  className?: string;
  theme?: ButtonColorThemeOption;
}

export interface ArticleBackButtonProps extends ButtonProps {
  label: string;
}

const ArticleBackButton: React.FC<ArticleBackButtonProps> = ({
  label,
  className = "",
  theme = ButtonColorTheme.DEFAULT,
}) => {
  const classes = useMemo(() => `button ${className} ${styles.articleBackButton} ${styles[theme]}`, [className, theme]);

  return (
    <Link className={classes} type="button" to="/news/">
      <div className={styles.buttonContent}>
        <Icon className={styles.icon} name="Chevron" />
        <div className={`label-02 ${styles.label}`}>
          <span className={styles.labelText}>{label}</span>
        </div>
      </div>
    </Link>
  );
};

export default ArticleBackButton;
