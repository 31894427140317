import React, { useEffect, useRef } from "react";
import { ContentstackArticleGalleryBlock } from "../../../../data/types/Contentstack";
import { useSlider } from "../../../../hooks/useSlider";
import SecondaryButton from "../../../general/button/SecondaryButton/SecondaryButton";
import Icon from "../../../general/Icon/Icon";
import styles from "./GalleryBlock.module.scss";
import { isIE } from "../../../../utils/browser-detect";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import gsap from "gsap";

export const GalleryBlock: React.FC<ContentstackArticleGalleryBlock> = ({ title, items }) => {
  const isMobile = useIsMobile();
  const {
    sliderRef,
    actions: { goToNext, goToPrevious },
    data: { activeSlideIndex, scrollSnaps },
  } = useSlider({ loop: true });

  const captionRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    gsap.fromTo(captionRef.current, { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 });
  }, [activeSlideIndex]);

  return (
    <div className={styles.galleryBlock}>
      <h2 className={`heading-02 ${styles.title}`}>{title}</h2>
      <div className={styles.sliderWrapper}>
        <div className={styles.slider} ref={sliderRef}>
          <ul className={styles.sliderContent}>
            {items.map((item, index) => (
              <li key={index} className={styles.slide}>
                {isIE() ? (
                  <span className={styles.ieImage} style={{ backgroundImage: `url(${item.image.url})` }} />
                ) : (
                  <img className={styles.image} src={item.image.url} alt={item.title} />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.controlsWrapper}>
          {isMobile ? (
            <Icon className={styles.frame} name="GalleryBlockMobileFrame" />
          ) : (
            <Icon className={styles.frame} name="GalleryBlockDesktopFrame" />
          )}
          <div className={styles.arrowsWrapper}>
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowPrev}`}
              onClick={goToPrevious}
              icon="LongArrow"
              ariaLabel="previous"
            />
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowNext}`}
              onClick={goToNext}
              icon="LongArrow"
              ariaLabel="next"
            />
          </div>
        </div>
      </div>

      <p ref={captionRef} className={styles.captions}>
        {items[activeSlideIndex].title}
      </p>

      <ul className={styles.bullets}>
        {scrollSnaps.map((_, index: number) => (
          <li key={index} className={`${styles.bulletWrapper} ${activeSlideIndex === index ? styles.isActive : ""}`}>
            <Icon className={styles.bulletIcon} name="Diamond" />
          </li>
        ))}
      </ul>
    </div>
  );
};
