import React from "react";
import { ContentstackUrl } from "../../../../data/types/Contentstack";
import styles from "./ArticleHero.module.scss";
import { isIE } from "../../../../utils/browser-detect";
import ArticleBackButton from "../../../general/button/ArticleBackButton/ArticleBackButton";

interface ArticleHeroProps {
  image: ContentstackUrl;
  alignment: "Top" | "Center" | "Bottom";
  backButtonCopy: string;
}

export const ArticleHero: React.FC<ArticleHeroProps> = ({ image, alignment, backButtonCopy }) => (
  <div className={styles.articleHero}>
    <ArticleBackButton className={` ${styles.backButton}`} label={backButtonCopy} />
    {isIE() ? (
      <div
        className={styles.ieBackgroundImage}
        style={{
          backgroundImage: `url(${image.url})`,
          backgroundPosition: `50% ${alignment}`,
        }}
      />
    ) : (
      <picture className={styles.backgroundImageWrapper}>
        <img
          src={image.url}
          className={styles.backgroundImage}
          alt="hero background"
          style={{ objectPosition: `50% ${alignment}` }}
        />
      </picture>
    )}
  </div>
);
