import React from "react";
import styles from "./AuthorCard.module.scss";
import { ContentstackAsset } from "../../../../data/types/Contentstack";
import { isIE } from "../../../../utils/browser-detect";
interface AuthorCardProps {
  className?: string;
  name: string;
  role?: string;
  description?: string;
  picture?: ContentstackAsset;
  fallbackImage: ContentstackAsset;
}

export const AuthorCard: React.FC<AuthorCardProps> = ({
  className = "",
  name,
  role,
  description,
  picture,
  fallbackImage,
}) => {
  return (
    <div className={`${className} ${styles.authorCard}`}>
      <span className={styles.dashDetail} />
      <article className={styles.contentWrapper}>
        <header className={styles.headerWrapper}>
          {isIE() ? (
            <span
              className={styles.ieAuthorPicture}
              style={{ backgroundImage: `url(${picture ? picture.url : fallbackImage?.url})` }}
            />
          ) : (
            <picture className={styles.authorPictureWrapper}>
              <img
                className={styles.authorPicture}
                src={picture ? picture.url : fallbackImage?.url}
                alt={picture ? picture.title : fallbackImage?.title}
              />
            </picture>
          )}
          <div className={styles.authorInfoWrapper}>
            {name && <h3 className={`heading-05 ${styles.authorName}`}>{name}</h3>}
            {role && <h4 className={`copy-01 ${styles.authorRole}`}>{role}</h4>}
          </div>
        </header>

        {description && <p className={`copy-01 ${styles.description}`}>{description}</p>}
      </article>
    </div>
  );
};
