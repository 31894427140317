import React, { useMemo } from "react";
import { Link } from "gatsby-plugin-intl";
import Icon from "../../Icon/Icon";
import styles from "./LinkButton.module.scss";
import { ButtonColorTheme, ButtonColorThemeOption } from "../../../../data/enum/ColorTheme";

export interface ButtonProps {
  className?: string;
  href?: string;
  onClick?: () => void;
  theme?: ButtonColorThemeOption;
}

type ButtonType = "button" | "submit" | "reset";
type ButtonTargetType = "_blank" | "_self";

export interface LinkButtonProps extends ButtonProps {
  label: string;
  enabled?: boolean;
  target?: ButtonTargetType;
  to?: string;
  type?: ButtonType;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  label,
  href,
  to,
  onClick,
  className = "",
  type = "button",
  target = "_blank",
  theme = ButtonColorTheme.DEFAULT,
  enabled = true,
}) => {
  const classes = useMemo(
    () => `button ${className} ${styles.linkButton} ${styles[theme]} ${!enabled ? styles.disabled : ""}`,
    [className, theme, enabled],
  );

  const child = useMemo(
    () => (
      <>
        <div className={`label-01 ${styles.label}`}>
          <span className={styles.labelText}>{label}</span>
        </div>
        <Icon className={styles.icon} name="Chevron" />
      </>
    ),
    [label],
  );

  if (to) {
    return (
      <Link to={enabled ? (to === "/" ? "/" : `/${to}`) : ""} onClick={onClick} className={classes}>
        {child}
      </Link>
    );
  } else if (href) {
    return (
      <a
        href={enabled ? href : undefined}
        onClick={onClick}
        className={classes}
        target={target}
        rel="noopener noreferrer"
      >
        {child}
      </a>
    );
  }

  return (
    <button className={classes} type={type} onClick={onClick}>
      {child}
    </button>
  );
};

export default LinkButton;
