import { Expo, gsap } from "gsap";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { ContentstackAccordionItem } from "../../../data/types/Contentstack";
import { RichText } from "../../general/RichText/RichText";
import styles from "./Accordion.module.scss";

interface AccordionProps {
  items: Array<ContentstackAccordionItem>;
  initialIndex?: number;
  className?: string;
  isNewsArticle?: boolean;
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  items,
  className,
  initialIndex = 0,
  isNewsArticle = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const itemsContentRef = useRef<Array<HTMLDivElement | null>>([]);

  const toggleAccordionItem = useCallback(
    ({ currentTarget }: React.MouseEvent) => {
      const targetIndex = Number(currentTarget.getAttribute("data-index"));

      if (targetIndex === activeIndex) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(targetIndex);
      }
    },
    [activeIndex],
  );

  useEffect(() => {
    if (activeIndex === -1) return;

    const currentElement = itemsContentRef.current[activeIndex];
    const currentElementContent = currentElement?.firstElementChild as HTMLSpanElement | null;
    const currentElementContentDashDetail = currentElementContent?.lastElementChild as HTMLSpanElement;
    gsap.to(currentElement, { height: currentElementContent?.offsetHeight, duration: 0.75, ease: Expo.easeInOut });

    gsap.fromTo(
      currentElementContent,
      { opacity: 0, x: 100 },
      { opacity: 1, x: 0, duration: 0.75, ease: Expo.easeInOut },
    );

    gsap.fromTo(
      currentElementContentDashDetail,
      { scaleX: 0 },
      { scaleX: 1, duration: 0.9, delay: 0.1, ease: Expo.easeInOut },
    );

    return () => {
      currentElement && gsap.to(currentElement, { height: 0, duration: 0.75 });
    };
  }, [activeIndex]);

  return (
    <div className={`${styles.accordion} ${className} ${isNewsArticle ? styles.newsArticle : ""}`}>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          <div className={styles.header} onClick={toggleAccordionItem} data-index={index}>
            <div className={`${styles.titleWrapper} ${activeIndex === index && styles.active}`}>
              {item.icon && (
                <img className={styles.icon} alt={`${item.title.toLowerCase()} icon`} src={item.icon.url} />
              )}
              <h4 className={`heading-04 font-normal ${styles.title}`}>{item.title}</h4>
            </div>
            <span className={`${styles.indicator} ${activeIndex === index && styles.active}`}></span>
          </div>
          <div className={`copy-01 ${styles.description}`} ref={(el) => (itemsContentRef.current[index] = el)}>
            <div className={styles.decriptionCopy}>
              <RichText content={item.description} />
              <span className={styles.dashDetail} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
