let promise: Promise<void> | null = null;
const id = "twitter-wjs";
export const loadTwitter = (): Promise<void> => {
  if (promise) return promise;

  promise = new Promise((resolve) => {
    window.twttr = (() => {
      const firstScriptTag = document.getElementsByTagName("script")[0];
      const twitter = window.twttr || {};
      if (document.getElementById(id)) return twitter;
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.id = id;
      firstScriptTag.parentNode?.insertBefore(script, firstScriptTag);

      twitter._e = [];
      twitter.ready = (f: Function) => {
        twitter._e.push(f);
      };

      return twitter;
    })();
    window.twttr.ready(resolve);
  });

  return promise;
};
