import React, { useMemo, useCallback } from "react";
import Icon from "../Icon/Icon";

type FacebookShareProps = {
  iconClassName?: string;
  anchorClassName?: string;
};

export const FacebookShare: React.FC<FacebookShareProps> = ({ iconClassName, anchorClassName }) => {
  const shareUrl = useMemo(() => {
    return encodeURI(
      `https://www.facebook.com/sharer/sharer.php?u=${typeof window !== "undefined" ? window.location.href : ""}`,
    );
  }, []);

  const handleClick = useCallback(() => {
    typeof window !== "undefined" && window.open(shareUrl, "popup", "width=600,height=480");
    return false;
  }, [shareUrl]);

  return (
    <a className={anchorClassName ? anchorClassName : ""} target="popup" onClick={handleClick}>
      <Icon className={iconClassName ? iconClassName : ""} name="Facebook" />
    </a>
  );
};
