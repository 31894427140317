import { graphql, PageProps } from "gatsby";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "../style/templates/NewsArticle.module.scss";
import { AccordionBlock } from "../components/block/news-article/AccordionBlock/AccordionBlock";
import { ButtonsBlock } from "../components/block/news-article/ButtonsBlock/ButtonsBlock";
import { CarouselBlock } from "../components/block/news-article/CarouselBlock/CarouselBlock";
import { GalleryBlock } from "../components/block/news-article/GalleryBlock/GalleryBlock";
import { SeparatorBlock } from "../components/block/news-article/SeparatorBlock/SeparatorBlock";
import { TwitchBlock } from "../components/block/news-article/TwitchBlock/TwitchBlock";
import { TwitterBlock } from "../components/block/news-article/TwitterBlock/TwitterBlock";
import { VideoBlock } from "../components/block/news-article/VideoBlock/VideoBlock";
import { YouTubeBlock } from "../components/block/news-article/YouTubeBlock/YouTubeBlock";
import { ChampionBlock } from "../components/block/news-article/ChampionBlock/ChampionBlock";
import { ArticleFooter } from "../components/content/news-article/ArticleFooter/ArticleFooter";
import { ArticleHeader } from "../components/content/news-article/ArticleHeader/ArticleHeader";
import { ArticleHero } from "../components/content/news-article/ArticleHero/ArticleHero";
import { ContentstackArticlesGlobal, ContentstackNewsArticle } from "../data/types/Contentstack";
import { removeNullFromObject } from "../utils/removeNullFromObject";
import { RelatedArticles } from "../components/content/news-article/RelatedArticles/RelatedArticles";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const RichTextBlock = React.lazy(() => import("../components/block/news-article/RichTextBlock/RichTextBlock"));
const TableOfContents = React.lazy(() => import("../components/content/news-article/TableOfContents/TableOfContents"));

interface NewsArticleProps {
  contentstackArticles: ContentstackNewsArticle;
  allContentstackArticles: {
    allArticles: Array<ContentstackNewsArticle>;
  };
  contentstackArticlesGlobal: {
    articlesGlobal: ContentstackArticlesGlobal;
  };
}

interface NewsArticleProps2 {
  data?: {
    result?: {
      data?: {
        contentstackArticles?: ContentstackNewsArticle;
        allContentstackArticles?: {
          allArticles?: Array<ContentstackNewsArticle>;
        };
        contentstackArticlesGlobal?: {
          articlesGlobal?: ContentstackArticlesGlobal;
        };
      }
    }
  }
}

const NewsArticle: React.FC<PageProps<NewsArticleProps>> = ({
  pageContext,
}) => {
  let pageContextObj: NewsArticleProps2 = pageContext;
  let articleData = pageContextObj?.data?.result?.data;
  const newsArticle = articleData?.contentstackArticles;
  const articleBody = articleData?.contentstackArticles?.articleBody;
  const tags = articleData?.contentstackArticles?.tags;
  const id = articleData?.contentstackArticles?.id;
  const allArticles = articleData?.allContentstackArticles?.allArticles;
  const featuredImage = articleData?.contentstackArticles?.featuredImage;
  const articlesGlobal = articleData?.contentstackArticlesGlobal?.articlesGlobal;

  // removeNullFromObject(articleBody);
  // useEffect(() => removeNullFromObject(articleBody), [articleBody]);

  const [relatedArticles, setRelatedArticles] = useState<Array<ContentstackNewsArticle>>([]);

  const articleRef = useRef<HTMLElement>(null);
  const asideRef = useRef<HTMLElement>(null);

  const isSSR = typeof window === "undefined";

  const authorsBlock = articleBody?.find((block) => block.authorsBlock)?.authorsBlock;

  // return <></>
  useEffect(() => {
    const currentArticleTags = tags?.map((tag) => tag.title);

    const allArticlesTags = allArticles?.map((article) => article.tags.map((tag) => tag.title));

    const matchingArticles = allArticlesTags?.map((articles) =>
      currentArticleTags?.some((tag) => articles.includes(tag)),
    );

    let filteredArticles = allArticles?.filter((_, index) => matchingArticles![index])
      .filter((article) => article.id !== id)
      .slice(0, 5);

    if (!filteredArticles?.length) {
      filteredArticles = allArticles?.filter((article) => article.id !== id).slice(0, 5);
    }

    setRelatedArticles(filteredArticles!);
  }, [allArticles, tags, id]);

  useEffect(() => {
    if (!articleRef.current || !asideRef.current) return;

    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: articleRef.current,
      start: "top 45px",
      end: "bottom bottom",
      pin: asideRef.current,
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 500);
  }, []);
  if (!newsArticle || !articlesGlobal) {
    return <></>
  }
  return (
    <>
      <ArticleHero image={featuredImage!.banner} alignment={featuredImage!.focalPoint} backButtonCopy={articlesGlobal!.backButtonCopy} />
      <section className={`container ${styles.articleSection}`}>
        <article className={`${styles.article} ${!newsArticle!.showTableOfContents ? styles.widerArticle : ""}`} ref={articleRef}>
          <div className={styles.articleContent}>
            <ArticleHeader
              title={newsArticle!.title}
              date={newsArticle!.date}
              description={newsArticle!.description}
              categories={newsArticle!.categories}
              authorsCopy={articlesGlobal!.authorsCopy}
              authorsBlock={authorsBlock}
            />
            {articleBody!.map((element, index) => {
              if (element.accordionBlock) return <AccordionBlock key={index} {...element.accordionBlock} />;
              if (element.buttonsBlock) return <ButtonsBlock key={index} {...element.buttonsBlock} />;
              if (element.carouselBlock) return <CarouselBlock key={index} {...element.carouselBlock} />;
              if (element.galleryBlock) return <GalleryBlock key={index} {...element.galleryBlock} />;
              if (element.richTextBlock)
                return (
                  !isSSR && (
                    <React.Suspense key={index} fallback={<div />}>
                      <RichTextBlock className={styles.richTextBlock} {...element.richTextBlock} />
                    </React.Suspense>
                  )
                );
              if (element.separatorBlock) return <SeparatorBlock key={index} {...element.separatorBlock} />;
              if (element.twitchBlock) return <TwitchBlock key={index} {...element.twitchBlock} />;
              if (element.twitterBlock) return <TwitterBlock key={index} {...element.twitterBlock} />;
              if (element.videoBlock) return <VideoBlock key={index} {...element.videoBlock} />;
              if (element.youtubeBlock) return <YouTubeBlock key={index} {...element.youtubeBlock} />;
              if (element.changesBlock) return <ChampionBlock key={index} {...element.changesBlock} baseStatsCopy={articlesGlobal!.baseStatsCopy} />;
            })}
            <ArticleFooter
              tags={newsArticle!.tags}
              authorsBlock={authorsBlock}
              tagsCopy={articlesGlobal!.tagsCopy}
              shareCopy={articlesGlobal!.shareCopy}
              authorFallbackImage={articlesGlobal!.authorFallbackImage}
            />
          </div>

          {!isSSR && newsArticle!.showTableOfContents && (
            <aside className={styles.aside} ref={asideRef}>
              <React.Suspense fallback={<div />}>
                <div className={`${styles.tableWrapper}`}>
                  <TableOfContents articleBody={articleBody!} titleCopy={articlesGlobal!.tableOfContentsCopy} />
                </div>
              </React.Suspense>
            </aside>
          )}
        </article>
      </section>
      <RelatedArticles titleParts={articlesGlobal!.relatedArticlesCopyParts} articles={relatedArticles} />
    </>
  );
};

export const query = graphql`
  query NewsArticleQuery {
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default NewsArticle;
