import React from "react";
import { ContentstackArticleChangesBlock } from "../../../../data/types/Contentstack";
import { getAbilityIndex } from "../../../../utils/championAbilities";
import styles from "./ChampionBlock.module.scss";

export const ChampionBlock: React.FC<ContentstackArticleChangesBlock> = ({ title, changes, baseStatsCopy }) => (
  <div className={styles.contentWrapper}>
    <span id="champion-changes" className={styles.championAnchorLink} />
    <h2 className={styles.sectionHeader}>{title}</h2>
    {changes.map((change) => {
      const [champion] = change.champions;

      return (
        <div className={styles.championContainer} key={champion.name}>
          <div className={styles.championImageWrapper}>
            <span id={`${champion.name.toLowerCase()}`} className={styles.championAnchorLink} />
            <span className={styles.dashDetail} />
            <img className={styles.championImage} src={champion.splash.url} alt={`${champion.name} Image`} />
          </div>
          <div className={styles.championTextWrapper}>
            <h3>{champion.name}</h3>
            <p>{change.changeDescription}</p>
            <ul className={styles.championChangesList}>
              {change.changelist.map((changeDetail, index) => {
                const abilityIndex = getAbilityIndex(changeDetail.ability);

                return (
                  <>
                    <hr className={styles.championChangesListItemSeparator} />
                    <li className={styles.championChangesListItem} key={index}>
                      {abilityIndex >= 0 && (
                        <img
                          className={styles.championChangesIcon}
                          src={champion.abilities[abilityIndex].icon.url}
                          alt="Ability Icon"
                        />
                      )}
                      <div className={styles.championChangesListItemContent}>
                        <h4>{abilityIndex >= 0 ? champion.abilities[abilityIndex].name : baseStatsCopy}</h4>
                        <span dangerouslySetInnerHTML={{ __html: changeDetail.change }} />
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      );
    })}
  </div>
);
